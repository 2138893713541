<template>
	<div>
		<div class="selections">
			Daily Panchang at <b>{{selected}}</b>
			<br/> 
			<select  v-model="selected">
				<option v-for="place in places" :key="place.id">{{place.name}}</option>
			</select>
			<br/> 
			<button @click="previousDate">Prev</button>
			{{panchangDateString}}
			<button @click="nextDate">Next</button>
        </div>
		
		<panchang-graph :key="componentKey" :panchangDate="panchangDate" :city=selected></panchang-graph>
	</div>
</template>

<script>
	import moment from 'moment-timezone'
	moment.tz.setDefault('UTC');
	import PanchangGraph from './PanchangGraph.vue';

    export default {
        data() {
            return {
				selected: 'Bhubaneshwar',
				panchangDate: moment().local(),
				panchangDateString: '',
				componentKey: 0
            };
        },
        methods: {
			nextDate(){
				this.resetMenuSelections();
				this.panchangDate = this.panchangDate.add(1, 'days');
				this.panchangDateString = this.panchangDate.format('DD-MM-YYYY'); 
				return this.componentKey=!this.componentKey;
			},
			previousDate(){
				this.resetMenuSelections();
				this.panchangDate = this.panchangDate.subtract(1, 'days');
				this.panchangDateString = this.panchangDate.format('DD-MM-YYYY'); 
				return this.componentKey=!this.componentKey;
			},

			resetMenuSelections(){
				this.$store.commit('vmenuActive', false);
				const prevSelectedHmenuItem = this.$store.state.selectedHmenuItem;
				if (prevSelectedHmenuItem !== null)
					prevSelectedHmenuItem.selected = false;
			}
		},
		watch: {
			selected: function (value) {
				return this.componentKey=!this.componentKey;
			}
		},
		computed:{
			places(){
				return this.$store.state.cities;
			},
		},
		components:{
			PanchangGraph
		},
		mounted(){
			this.panchangDateString = this.panchangDate.format('DD-MM-YYYY'); 
		}
	}

</script>
<style scoped>
.selections{
	color: darkgrey;
}
</style>