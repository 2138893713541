<template>
	<div>
		<responsive-grid :gridlist="blogs" 
						v-on:gridItemClicked="clickFunction($event)"
						klass="boxshadow"> 
		</responsive-grid>
	</div>
</template>

<script>
import ResponsiveGrid from '@/components/util/ResponsiveGrid'
export default {
	props:['htmlfile'],
	data(){
		return {
			blogs: null
		}
	},
	methods:{
		clickFunction(gridItem){
			this.$store.commit('vmenuActive', false);
			const prevSelectedHmenuItem = this.$store.state.selectedHmenuItem;
			if (prevSelectedHmenuItem !== null)
				prevSelectedHmenuItem.selected = false;
			this.$emit("blogClicked", gridItem.blogFileName);
		}
	},
	components:{
		ResponsiveGrid
	},
	async mounted(){
		await this.axios.get(process.env.VUE_APP_SERVER_URL + "/blog/" + this.$store.state.menuPath)
		.then(response => {
			this.blogs = response.data.blogs;
		}).catch(error => alert(error));
	}
}
</script>

<style>
	.boxshadow{
		box-shadow: -10px 25px 50px rgba(0, 0, 0, 0.3);
	}
</style>