<template>
	<div>
		<component :htmlfile="htmlfile" v-bind:is="componentName" v-on:blogClicked="blogClickedFunction($event)" class="content"></component>
	</div>
</template>
<script>
import EmptyComponent from "@/components/EmptyComponent";
import DashBoard from "@/components/DashBoard"
import Panchang from "@/components/Panchang";
import BlogList from "@/components/BlogList";
import BlogContent from "@/components/BlogContent";
import Muhurtha from "@/components/Muhurtha";

export default {
	data() {
		return {
			hmenu: null, 
            vmenu: null,
			htmlfile: null
		}
	},
	methods:{
		blogClickedFunction(htmlfile){
			this.htmlfile = htmlfile;
			this.$store.state.mainComponentName = 'BlogContent';
		},
		getComponentNameViaURL(){
			let componentName = null;
			const menuLinks = this.$store.state.links;
			for (const hlink in menuLinks){
				if (menuLinks[hlink].name === this.hmenu){
					const vlinks = menuLinks[hlink].vmenus;
					for (const vlink in vlinks){
						if (vlinks[vlink].name === this.vmenu){
							componentName = vlinks[vlink].componentName;
						}
					}
				}
			}
			if (this.htmlfile){
				if (componentName == 'BlogList'){
					componentName = 'BlogContent';
				}
			}
			return componentName;
		},
		getFileName(){
			if (this.vmenu === 'soccer'){
				//return "test.html";
				return "indexAstro.html";
			}
		}
	},
	computed:{
		isHome(){
			return !(this.hmenu) && !(this.vmenu);
		},
		isBlog(){
			return this.hmenu === "Blog";
		},
		componentName(){
			let componentName = this.$store.state.mainComponentName;
			if (this.$route.path !== '/'){
				const componentNameViaURL = this.getComponentNameViaURL();
				if (componentNameViaURL)
					componentName = componentNameViaURL
			}
			return componentName;
		},
	},
	beforeMount(){
        const pathParams = this.$route.params.catchAll.split('/');
        for(let i=0; i < pathParams.length; i++){
            if (i === 0){
                this.hmenu = pathParams[i];
            }
            else if ( i === 1)
                this.vmenu = pathParams[i];
            else if ( i === 2)
                this.htmlfile = pathParams[i];
            else
                break;
        }
    },
	mounted(){
		if (!this.isHome){
			const fileName = this.getFileName();
			const path = "/"+this.hmenu+"/"+this.vmenu+"/" + fileName;
			fetch(path)
				.then(response => {
					return response.text();
				})
				.then(data => {
					this.page = data;
				}
			)
		}
	},
	components:{
		EmptyComponent,
		DashBoard,
		Panchang,
		BlogList,
		BlogContent,
		Muhurtha
	}
};
</script>

<style scoped>
.content{
	background-image: var(--bg-color-two);
	margin: 1px;
	color: darkgrey;
}
</style>