<template>
	<div class="grid">
		<div v-for="griditem in gridlist" :key="griditem.id">
			<responsive-grid-item :griditem="griditem" 
								v-on:gridItemClicked="clickFunction($event)"
								:klass="klass"></responsive-grid-item>
		</div>
	</div>
</template>
<script>
import ResponsiveGridItem from '@/components/util/ResponsiveGridItem'

export default {
	props: ['gridlist', 'klass'],
	computed:{
		
	},
	methods:{
		clickFunction(gridItem){
			this.$emit('gridItemClicked', gridItem);
		}
	},
	components:{
        ResponsiveGridItem
    }
}
</script>
<style scoped>
	.grid {
		/* Define the element as a grid container */
		display: grid;
		/* Auto-fit as many items on a row as possible without going under 180px */
		grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
		/* A little spacing between articles */
		grid-gap: 1em;
		margin-left: .5em;
		margin-right: .5em;
	}

	/* Single column display for phones */
	@media (max-width: 459px) {
		.grid {
			display: flex;
			flex-direction: column;
		}
	}
</style>